// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cause-american-revolution-simulator-js": () => import("./../../../src/pages/cause-american-revolution-simulator.js" /* webpackChunkName: "component---src-pages-cause-american-revolution-simulator-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-net-worth-simulator-js": () => import("./../../../src/pages/net-worth-simulator.js" /* webpackChunkName: "component---src-pages-net-worth-simulator-js" */),
  "component---src-pages-president-selection-js": () => import("./../../../src/pages/president-selection.js" /* webpackChunkName: "component---src-pages-president-selection-js" */),
  "component---src-pages-roman-emperor-simulator-claudius-js": () => import("./../../../src/pages/roman-emperor-simulator-claudius.js" /* webpackChunkName: "component---src-pages-roman-emperor-simulator-claudius-js" */),
  "component---src-pages-roman-emperor-simulator-js": () => import("./../../../src/pages/roman-emperor-simulator.js" /* webpackChunkName: "component---src-pages-roman-emperor-simulator-js" */),
  "component---src-pages-us-president-simulator-2-js": () => import("./../../../src/pages/us-president-simulator-2.js" /* webpackChunkName: "component---src-pages-us-president-simulator-2-js" */),
  "component---src-pages-us-president-simulator-3-js": () => import("./../../../src/pages/us-president-simulator-3.js" /* webpackChunkName: "component---src-pages-us-president-simulator-3-js" */),
  "component---src-pages-us-president-simulator-george-js": () => import("./../../../src/pages/us-president-simulator-george.js" /* webpackChunkName: "component---src-pages-us-president-simulator-george-js" */)
}

